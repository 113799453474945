import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"

const KnowledgeBasePage = () => (
  <MainContainer>
    <HeadData
      title='Command Access Knowledge Base'
    />

    <iframe
      title="Command Access Knowledge Base"
      src="http://commandaccess-9298297.hs-sites.com/knowledge"
      width="100%"
      height="700"
      frameborder="0"
      scrolling="yes"
    ></iframe>
  </MainContainer>
)

export default KnowledgeBasePage